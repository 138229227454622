body {
  font-family: "Roboto", sans-serif;
  // show auto-complete suggestions in modal
  div[role="tooltip"] {
    z-index: 2000;
  }
  // margin: 0 0 8px 0;
  margin: 0 !important;
  padding: 0 !important;
}

// layout shold fill the whole screen
.layout {
  min-height: calc(100vh - 16px);
}
div[class*="Layout-appFrame"] {
  flex-grow: 1;
}
// show auto-complete suggestions in modal
// div[class*='makeStyles-suggestionsContainer'] {
//   z-index: 2000;
// }

.row-wrapper {
  margin-bottom: 30px;
  // remove box-shadow for asides
  div {
    div {
      div[class*="MuiCard-root"] {
        box-shadow: none;
      }
    }
  }
}

.show-list {
  // remove box-shadow lists in a show
  div {
    div {
      div[class*="MuiCard-root"] {
        box-shadow: none;
      }
    }
  }
}

.show-items > div > div {
  border-bottom: 1px solid #d8d8d8;
}
